import {
  BuildingType,
  ConstructionDecade,
  HeatingDistributionMethod,
  HeatingFuel,
  Language,
  ReportTypeInput,
  Template,
  ThemeName,
} from '../api-types'

import globalConstants from '../constants-generated.json'

const buildingDetails = {
  building_type: 'APARTMENT' as BuildingType,
  construction_decade: 1970 as ConstructionDecade,
  has_heat_recovery: false,
  has_mechanical_ventilation: true,
  heated_floor_area: 100,
  heating_fuel: 'ELECTRICITY' as HeatingFuel,
  heating_distribution_method: 'WATER' as HeatingDistributionMethod,
  street_address: '',
  suitable_roof_area_for_solar: 99,
}

const drilling = {
  building_footprint: 0,
  lot_size: 0,
}

const otherDefaults = {
  cop_multiplier: 1.0,
  number_of_ac_machines: 1,
  report_type: 'gsaw' as ReportTypeInput,
  vat_percentage: 0.255,
  user_details: { email: '' },
}

const constants = {
  calculation_lifetime: globalConstants.CALCULATION_LIFETIME,
  electricity_annual_increase: globalConstants.ELECTRICITY_ANNUAL_INCREASE,
  heat_pump_coverage: globalConstants.HEAT_PUMP_COVERAGE,
  heating_annual_increase: globalConstants.HEATING_ANNUAL_INCREASE,
  interest_per_year: globalConstants.INTEREST_PER_YEAR,
  loan_maturity_years: globalConstants.LOAN_MATURITY_YEARS,
  year_now_spot_price: globalConstants.YEAR_NOW_DEFAULT_SPOT_PRICE,
  eaas_monthly_capex_multiplier: 0,
}

const calculatedSystemParameters = {
  exhaust_airflow: null,
  heat_recovery_capacity: null,
  flow_temperature: null,
}

const calculatedOtherParameters = {
  elec_consumption_mwh: null,
  heat_consumption_mwh: null,
  hotwater_consumption_mwh: null,
  number_of_aa_units: 0,
  number_of_habitants: null,
}

const defaultValues = {
  ...buildingDetails,
  ...drilling,
  ...otherDefaults,
  ...constants,
  ...calculatedSystemParameters,
  ...calculatedOtherParameters,
}

const getDefaultValues = (
  themeName: ThemeName,
  template: Template,
  language: Language,
  showSolar: boolean
) => ({
  ...defaultValues,
  calculate_energy_efficiency: showSolar,
  calculate_solar: showSolar,
  theme_name: themeName,
  language,
  template,
})

export default getDefaultValues

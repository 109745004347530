import { Field, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PdfApiInput } from '../api-types'
import { CustomTextField } from '../fields'

export default function EaasField() {
  const {
    values: { eaas_monthly_capex_multiplier: eaasMultiplier, theme_name: themeName },
    setFieldValue,
  } = useFormikContext<PdfApiInput>()
  const { t } = useTranslation()
  const label = t('fields.eaas')
  const [isHidden, setIsHidden] = useState(themeName !== 'GEONOVA')

  useEffect(() => {
    if (isHidden && themeName === 'GEONOVA') {
      if (!eaasMultiplier) {
        setFieldValue('eaas_monthly_capex_multiplier', 0.0078)
      }
      setIsHidden(false)
      return
    }
    if (!isHidden && themeName !== 'GEONOVA') {
      setIsHidden(true)
      setFieldValue('eaas_monthly_capex_multiplier', 0)
    }
  }, [themeName, isHidden, eaasMultiplier, setFieldValue])

  if (isHidden) {
    return null
  }

  return (
    <Field
      name="eaas_monthly_capex_multiplier"
      label={label}
      component={CustomTextField}
      numeric
      disableRound
    />
  )
}
